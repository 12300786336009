import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ToastrModule } from 'ngx-toastr';
import { Notification } from '../core/Notifications/Notification';
import { GlobalStorage } from '../core/Gloabl/Global';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';

// import { TimepickerModule } from 'ngx-bootstrap/timepicker';
// import { PopoverModule } from 'ngx-bootstrap/popover';
@NgModule({
    exports: [
        CommonModule,
        NgSelectModule,

    ],
    imports: [
        RouterModule,
        CommonModule,
        ToastrModule.forRoot(),
        DataTablesModule,
        NgSelectModule
    ],
    declarations: [

    ]
    , providers: [Notification, GlobalStorage
        // TimepickerModule.forRoot(), PopoverModule.forRoot()
     ]
})
export class sharedModule { }
