import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Navigation',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard'
  },
  {
    label: 'Customer Management',
    icon: 'book',
    subItems: [
      {
        label: 'Create Lead',
        link: '/customer-management/create-lead',
      },
      {
        label: 'Assign Lead',
        link: '/customer-management/assign-lead',
      },
      {
        label: 'Lead Follow up',
        link: '/customer-management/lead-follow-up',
      },
      {
        label: 'Lead Follow up History',
        link: '/customer-management/lead-follow-up-history',
      },
      {
        label: 'Member Due Follow up',
        link: '/customer-management/member-due-follow-up',
      },
      {
        label: 'Send SMS',
        link: '/customer-management/send-sms',
      },
    ]
  },
  {
    label: 'Admin',
    icon: 'book',
    subItems: [
      {
        label: 'Company Profile',
        link: '/admin/company-profile',
      },
      {
        label: 'Users',
        link: '/admin/users',
      },
      {
        label: 'Roles',
        link: '/admin/roles',
      },
      {
        label: 'Authorize',
        link: '/admin/authorize',
      },
      {
        label: 'Change Password',
        link: '/admin/changepassword',
      },
    ]
  },
  {
    label: 'Master',
    icon: 'book',
    subItems: [
      {
        label: 'State',
        link: '/master/state',
      },
      {
        label: 'District',
        link: '/master/district',
      },
      {
        label: 'Qualification',
        link: '/master/qualification',
      },
      {
        label: 'Designation',
        link: '/master/designation',
      },
      {
        label: 'Discipline',
        link: '/master/discipline',
      },
      {
        label: 'Specilization',
        link: '/master/specilization',
      },
      {
        label: 'Network',
        link: '/master/network',
      },
      {
        label: 'Hospital',
        link: '/master/hospital',
      },
        {
        label: 'Assistant',
        link: '/master/assistant',
      },
        {
        label: 'Vle',
        link: '/master/vle',
      },
        {
        label: 'Pharmacy',
        link: '/master/pharmacy',
      },
    ]
  },
  {
    label: 'Finance',
    icon: 'book',
    subItems: [
      {
        label: 'Fiscal Year',
        link: '/finance/fiscal-year',
      },
      {
        label: 'General Ledger',
        link: '/finance/general-ledger',
      },
      {
        label: 'Sub Ledger',
        link: '/finance/sub-ledger',
      },
      {
        label: 'Account Voucher',
        link: '/finance/accountvoucher',
      },
    ]
  },
 
];