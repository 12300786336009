import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: []
})
export class FinanceComponent implements OnInit {

  constructor(private router: Router ) { }

  ngOnInit(): void {
  }

}
