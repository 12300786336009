import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from '../../pages/auth/UserDetails/user.service';
import { GlobalStorage } from '../../../core/Gloabl/Global';
import { Notification} from '../../../core/Notifications/Notification';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  username: string;
  useremail: string;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private user: UserService,
    private store: GlobalStorage,
    private Notifi:Notification
  ) { }

  ngOnInit(): void {
    this.username = this.store.get_user_name();
    this.useremail = this.store.get_user_type();
    //this.Notifi.successmsg(this.username +" logged Successfully.");
  }

  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    this.user.logout();
    this.router.navigate(['/auth/login']);
  }

}
